import { deep } from '@theme-ui/presets'

const theme = {
  ...deep,
  config: {
    initialColorModeName: 'light',
    useRootStyles: false
  },
  colors: {
    ...deep.colors,
    text: 'black',
    background: 'white',
    modes: {
      dark: {
        text: deep.colors.text,
        // background: deep.colors.text,
        // primary: deep.colors.background,
        background: deep.colors.background,
        // secondary: deep.colors.secondary
      }
    }
  },
}

export default theme